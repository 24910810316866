import { PATH_NAME } from 'configs';
import { EnglishTranslation } from 'locales';

import { Role } from 'models/user';

import { SystemPermission } from 'types';
import { InternalPermission, PartnerPermission } from 'types';

import {
  BillingIcon,
  BuildingIcon,
  ConfigurationIcon,
  CreditIcon,
  EducationIcon,
  FeedIcon,
  FolderIcon,
  PaymentIcon,
  PlaceIcon,
  ResumeIcon,
  SearchIcon,
  TooltipIcon,
  UserIcon,
  UserPermissionIcon,
  WriteIcon,
  ScheduleIcon,
  WorkforceIcon,
  OutletIcon,
  PayrollIcon,
  AdminIcon,
} from 'assets/icons';
import { userPermission } from 'pro/api/user';

export type MenuConfig = {
  title: keyof EnglishTranslation;
  titleInMobile?: keyof EnglishTranslation;
  path: string;
  icon: React.FC;
  roles?: Role[];
  permissions?: number;
  subMenu?: Array<Omit<MenuConfig, 'subMenu' | 'icon'>>;
  enableEnv?: Array<'DEV' | 'STAGING' | 'PROD'>;
  canReplaceParent?: boolean;
};

export const navMenuConfig: MenuConfig[] = [
  // {
  //   title: 'title.howToUse',
  //   path: PATH_NAME.HOW_TO_USE,
  //   icon: TooltipIcon,
  //   roles: [Role.HqManager, Role.AreaManager, Role.LocationManager],
  //   permissions: [
  //     PartnerPermission.VIEW_POST_JOBS,
  //     PartnerPermission.VIEW_MAKE_SELECTIONS,
  //     PartnerPermission.VIEW_CLOCK_IN_AND_OUT,
  //   ],
  //   subMenu: [
  //     {
  //       title: 'title.howToPostJobs',
  //       path: PATH_NAME.HOW_TO_POST_JOBS,
  //       roles: [Role.HqManager, Role.AreaManager, Role.LocationManager],
  //       permissions: [PartnerPermission.VIEW_POST_JOBS],
  //     },
  //     {
  //       title: 'title.howToMakeSelections',
  //       path: PATH_NAME.HOW_TO_MAKE_SELECTIONS,
  //       roles: [Role.HqManager, Role.AreaManager, Role.LocationManager],
  //       permissions: [PartnerPermission.VIEW_MAKE_SELECTIONS],
  //     },
  //     {
  //       title: 'title.howToClockInAndOut',
  //       path: PATH_NAME.HOW_TO_CLOCK_IN_AND_OUT,
  //       roles: [Role.HqManager, Role.AreaManager, Role.LocationManager],
  //       permissions: [PartnerPermission.VIEW_CLOCK_IN_AND_OUT],
  //     },
  //   ],
  // },
  {
    title: 'title.dashboard',
    path: PATH_NAME.DASHBOARD,
    icon: FeedIcon,
    // permissions: [
    //   InternalPermission.VIEW_COMPANY,
    //   InternalPermission.VIEW_HQ_USER,
    //   InternalPermission.VIEW_PAYMENT_FUTURE,
    //   InternalPermission.VIEW_PAYMENT_SUMMARY,

    //   InternalPermission.CHANGE_DASHBOARD_LOGO,
    //   InternalPermission.VIEW_JOB_TYPE,
    //   InternalPermission.VIEW_LOCATION,
    //   InternalPermission.VIEW_APPLICANT,
    //   InternalPermission.VIEW_EDUCATIONAL_INSTITUTE,

    //   PartnerPermission.VIEW_JOB_OVERVIEW,
    //   PartnerPermission.VIEW_HIRING_OVERVIEW,
    //   PartnerPermission.VIEW_CREDIT_OVERVIEW,
    //   PartnerPermission.VIEW_RATING_OVERVIEW,
    //   PartnerPermission.DOWNLOAD_SERVICE_CONTRACT,
    // ],
  },
  {
    title: 'title.companies',
    path: PATH_NAME.COMPANIES,
    icon: BuildingIcon,
    // permissions: [InternalPermission.VIEW_COMPANY],
  },
  {
    title: 'title.company',
    path: PATH_NAME.COMPANY,
    icon: BuildingIcon,
    // permissions: [PartnerPermission.VIEW_COMPANY],
  },
  // {
  //   title: 'title.jobTypes',
  //   path: PATH_NAME.JOB_TYPE,
  //   icon: SearchIcon,
  //   permissions: [InternalPermission.VIEW_JOB_TYPE],
  // },
  // // {
  // //   title: 'title.hqUsers',
  // //   path: PATH_NAME.HQ_USER,
  // //   icon: UserIcon,
  // //   permissions: [InternalPermission.VIEW_HQ_USER],
  // // },
  // {
  //   title: 'title.managers',
  //   path: PATH_NAME.MANAGER,
  //   icon: UserIcon,
  //   roles: [Role.SuperAdmin, Role.Internal, Role.UserAdmin],
  //   permissions: [InternalPermission.VIEW_HQ_USER, InternalPermission.VIEW_HIRING_MANAGERS],
  //   subMenu: [
  //     {
  //       title: 'title.hqUsers',
  //       path: PATH_NAME.HQ_USER,
  //       roles: [Role.SuperAdmin, Role.Internal, Role.UserAdmin],
  //       permissions: [InternalPermission.VIEW_HQ_USER],
  //     },
  //     {
  //       title: 'title.hiringManager',
  //       path: PATH_NAME.MANAGER,
  //       roles: [Role.SuperAdmin, Role.Internal, Role.UserAdmin],
  //       permissions: [InternalPermission.VIEW_HIRING_MANAGERS],
  //     },
  //     {
  //       title: 'title.superHqUsers',
  //       path: PATH_NAME.SUPERHQ_USER,
  //       roles: [Role.SuperAdmin, Role.Internal, Role.UserAdmin],
  //       permissions: [InternalPermission.VIEW_HIRING_MANAGERS],
  //     },
  //   ],
  // },
  // // {
  // //   title: 'title.managers',
  // //   path: PATH_NAME.MANAGER,
  // //   icon: UserIcon,
  // //   roles: [Role.SuperAdmin],
  // //   permissions: [InternalPermission.VIEW_MANAGER],
  // //   subMenu: [
  // //     {
  // //       title: 'title.managerList',
  // //       path: PATH_NAME.MANAGER,
  // //       roles: [Role.SuperAdmin],
  // //       permissions: [InternalPermission.VIEW_MANAGER],
  // //     },
  // //     {
  // //       title: 'title.addNewManager',
  // //       path: PATH_NAME.MANAGER_NEW,
  // //       roles: [Role.SuperAdmin],
  // //       permissions: [InternalPermission.CREATE_MANAGER],
  // //     },
  // //   ],
  // // },

  // {
  //   title: 'title.applicants',
  //   path: PATH_NAME.APPLICANT,
  //   icon: ResumeIcon,
  //   permissions: [InternalPermission.VIEW_APPLICANT],
  // },
  // {
  //   title: 'title.educationalInstitutes',
  //   path: PATH_NAME.EDUCATION_INSTITUTE,
  //   icon: EducationIcon,
  //   permissions: [InternalPermission.VIEW_EDUCATIONAL_INSTITUTE],
  // },
  // {
  //   title: 'title.locations',
  //   path: PATH_NAME.LOCATION,
  //   icon: PlaceIcon,
  //   permissions: [InternalPermission.VIEW_LOCATION],
  // },
  // {
  //   title: 'title.billingRecords',
  //   path: PATH_NAME.BILLING_RECORD,
  //   icon: BillingIcon,
  //   permissions: [InternalPermission.VIEW_BILLING_RECORD],
  // },
  // {
  //   title: 'title.jodCredits',
  //   path: PATH_NAME.JOD_CREDIT,
  //   icon: CreditIcon,
  //   permissions: [InternalPermission.VIEW_COMPANY_CREDIT_HISTORY, InternalPermission.ASSIGN_CREDIT],
  //   subMenu: [
  //     {
  //       title: 'title.assignCredit',
  //       path: PATH_NAME.JOD_CREDIT_ASSIGN,
  //       permissions: [InternalPermission.ASSIGN_CREDIT],
  //     },
  //     {
  //       title: 'title.creditHistory',
  //       path: PATH_NAME.JOD_CREDIT,
  //       permissions: [InternalPermission.VIEW_COMPANY_CREDIT_HISTORY],
  //     },
  //     {
  //       title: 'title.debitCreditHistory',
  //       path: PATH_NAME.JOD_DEBIT_CREDIT,
  //       permissions: [InternalPermission.VIEW_COMPANY_DEBIT_CREDIT_HISTORY],
  //     },
  //   ],
  // },
  // {
  //   title: 'title.payments',
  //   path: PATH_NAME.PAYMENT,
  //   icon: PaymentIcon,
  //   permissions: [
  //     InternalPermission.VIEW_PAYMENT_SUMMARY,
  //     InternalPermission.VIEW_PAYMENT_PROCESSED,
  //     InternalPermission.VIEW_PAYMENT_FUTURE,
  //   ],
  //   subMenu: [
  //     {
  //       title: 'title.jobSummary',
  //       path: PATH_NAME.PAYMENT_SUMMARY,
  //       permissions: [InternalPermission.VIEW_PAYMENT_SUMMARY],
  //     },
  //     {
  //       title: 'title.jobPayments',
  //       path: PATH_NAME.PAYMENT_PROCESSED,
  //       permissions: [InternalPermission.VIEW_PAYMENT_PROCESSED],
  //     },
  //     {
  //       title: 'title.futurePayments',
  //       path: PATH_NAME.PAYMENT_FUTURE,
  //       permissions: [InternalPermission.VIEW_PAYMENT_FUTURE],
  //     },
  //   ],
  // },
  // {
  //   title: 'title.report',
  //   path: PATH_NAME.REPORT,
  //   icon: FolderIcon,
  //   permissions: [InternalPermission.VIEW_REPORT],
  // },
  // {
  //   title: 'title.usersAndPermissions',
  //   path: PATH_NAME.ACCESS_CONFIGURATION,
  //   icon: UserPermissionIcon,
  //   permissions: [
  //     InternalPermission.VIEW_INTERNAL_USER,
  //     InternalPermission.VIEW_ROLE,
  //     InternalPermission.VIEW_PERMISSION,
  //   ],
  //   subMenu: [
  //     {
  //       title: 'title.users',
  //       path: PATH_NAME.INTERNAL_USER,
  //       permissions: [InternalPermission.VIEW_INTERNAL_USER],
  //     },
  //     {
  //       title: 'title.roles',
  //       path: PATH_NAME.INTERNAL_ROLE,
  //       permissions: [InternalPermission.VIEW_ROLE],
  //     },
  //     {
  //       title: 'title.permissions',
  //       path: PATH_NAME.PERMISSION,
  //       permissions: [InternalPermission.VIEW_PERMISSION],
  //     },
  //     // {
  //     //   title: 'title.superHqUsers',
  //     //   path: PATH_NAME.SUPERHQ_USER,
  //     //   permissions: [InternalPermission.VIEW_INTERNAL_USER],
  //     // },
  //   ],
  // },
  // {
  //   title: 'title.configuration',
  //   path: PATH_NAME.CONFIGURATION,
  //   icon: ConfigurationIcon,
  //   permissions: [
  //     InternalPermission.UPDATE_NOTIFICATION_CONFIGURATION,
  //     InternalPermission.UPDATE_OTHER_CONFIGURATION,
  //   ],
  //   subMenu: [
  //     {
  //       title: 'title.notifications',
  //       path: PATH_NAME.CONFIGURATION_NOTIFICATIONS,
  //       permissions: [InternalPermission.UPDATE_NOTIFICATION_CONFIGURATION],
  //     },
  //     {
  //       title: 'title.jodBadges',
  //       path: PATH_NAME.CONFIGURATION_JOD_BADGE,
  //       permissions: [InternalPermission.UPDATE_JOD_BADGE_CONFIGURATION],
  //     },
  //     {
  //       title: 'title.other',
  //       path: PATH_NAME.CONFIGURATION_OTHER,
  //       permissions: [InternalPermission.UPDATE_OTHER_CONFIGURATION],
  //     },
  //     {
  //       title: 'title.appConfig',
  //       path: PATH_NAME.CONFIGURATION_APP,
  //       permissions: [InternalPermission.UPDATE_OTHER_CONFIGURATION],
  //     },
  //   ],
  // },

  // {
  //   title: 'title.locations',
  //   path: PATH_NAME.LOCATION,
  //   icon: PlaceIcon,
  //   roles: [Role.HqManager],
  //   permissions: [PartnerPermission.VIEW_LOCATION, PartnerPermission.CREATE_LOCATION],
  //   subMenu: [
  //     {
  //       title: 'title.locationList',
  //       path: PATH_NAME.LOCATION,
  //       roles: [Role.HqManager],
  //       permissions: [PartnerPermission.VIEW_LOCATION],
  //     },
  //     {
  //       title: 'title.addNewLocation',
  //       path: PATH_NAME.LOCATION_NEW,
  //       roles: [Role.HqManager],
  //       permissions: [PartnerPermission.CREATE_LOCATION],
  //     },
  //   ],
  // },
  // {
  //   title: 'title.managers',
  //   path: PATH_NAME.MANAGER,
  //   icon: UserIcon,
  //   roles: [Role.HqManager],
  //   permissions: [PartnerPermission.VIEW_MANAGER, PartnerPermission.CREATE_MANAGER],
  //   subMenu: [
  //     {
  //       title: 'title.managerList',
  //       path: PATH_NAME.MANAGER,
  //       roles: [Role.HqManager],
  //       permissions: [PartnerPermission.VIEW_MANAGER],
  //     },
  //     {
  //       title: 'title.addNewManager',
  //       path: PATH_NAME.MANAGER_NEW,
  //       roles: [Role.HqManager],
  //       permissions: [PartnerPermission.CREATE_MANAGER],
  //     },
  //   ],
  // },

  // {
  //   title: 'title.listOfLocations',
  //   titleInMobile: 'title.locations',
  //   path: PATH_NAME.LOCATION,
  //   icon: PlaceIcon,
  //   roles: [Role.AreaManager],
  //   permissions: [PartnerPermission.VIEW_LOCATION],
  // },
  // {
  //   title: 'title.locationProfile',
  //   path: PATH_NAME.LOCATION_PROFILE,
  //   icon: PlaceIcon,
  //   roles: [Role.LocationManager],
  //   permissions: [PartnerPermission.VIEW_LOCATION],
  // },

  // {
  //   title: 'title.jobTemplates',
  //   path: PATH_NAME.JOB_TEMPLATE,
  //   icon: SearchIcon,
  //   permissions: [PartnerPermission.VIEW_JOB_TEMPLATE, PartnerPermission.CREATE_JOB_TEMPLATE],
  //   subMenu: [
  //     {
  //       title: 'title.jobTemplateList',
  //       path: PATH_NAME.JOB_TEMPLATE,
  //       permissions: [PartnerPermission.VIEW_JOB_TEMPLATE],
  //     },
  //     {
  //       title: 'title.addNewJobTemplate',
  //       path: PATH_NAME.JOB_TEMPLATE_NEW,
  //       permissions: [PartnerPermission.CREATE_JOB_TEMPLATE],
  //     },
  //   ],
  // },

  // {
  //   title: 'title.jobPosting',
  //   path: PATH_NAME.JOB,
  //   icon: FolderIcon,
  //   permissions: [PartnerPermission.VIEW_JOB, PartnerPermission.CREATE_JOB],
  //   subMenu: [
  //     {
  //       title: 'title.allJobPosting',
  //       path: PATH_NAME.JOB,
  //       permissions: [PartnerPermission.VIEW_JOB],
  //       canReplaceParent: true,
  //     },
  //     {
  //       title: 'title.createNewJob',
  //       path: PATH_NAME.JOB_NEW,
  //       permissions: [PartnerPermission.CREATE_JOB],
  //     },
  //   ],
  // },
  // {
  //   title: 'title.manageJobs',
  //   path: PATH_NAME.JOB_MANAGER,
  //   icon: WriteIcon,
  //   roles: [Role.HqManager],
  //   permissions: [PartnerPermission.BULK_UPDATE_JOB_WAGE, PartnerPermission.CANCEL_MULTIPLE_JOB],
  //   subMenu: [
  //     {
  //       title: 'title.updateJobs',
  //       path: PATH_NAME.JOB_BULK_UPDATE,
  //       roles: [Role.HqManager],
  //       permissions: [PartnerPermission.BULK_UPDATE_JOB_WAGE],
  //     },
  //     {
  //       title: 'title.cancelJobs',
  //       path: PATH_NAME.JOB_CANCEL,
  //       roles: [Role.HqManager],
  //       permissions: [PartnerPermission.CANCEL_MULTIPLE_JOB],
  //     },
  //   ],
  // },
  // {
  //   title: 'title.jodCredits',
  //   path: PATH_NAME.JOD_CREDIT,
  //   icon: CreditIcon,
  //   roles: [Role.HqManager, Role.AreaManager, Role.LocationManager],
  //   permissions: [PartnerPermission.VIEW_LOCATION_CREDIT, PartnerPermission.VIEW_CREDIT_HISTORY],
  // },
  {
    title: 'title.companies',
    path: PATH_NAME.COMPANIES,
    icon: OutletIcon,
    permissions: 0,
  },
  {
    title: 'title.company',
    path: PATH_NAME.COMPANY,
    icon: OutletIcon,
    permissions: 0,
  },
  {
    title: 'title.outlet',
    path: PATH_NAME.OUTLET,
    icon: OutletIcon,
    permissions: 10,
  },
  {
    title: 'title.workforce',
    path: PATH_NAME.WORKFORCE_MANAGER,
    icon: WorkforceIcon,
    subMenu: [
      {
        title: 'title.workforce.manager',
        path: PATH_NAME.WORKFORCE_MANAGER,
        permissions: 27,
      },
      {
        title: 'title.workforce.worker',
        path: PATH_NAME.WORKFORCE_WORKER,
        permissions: 34,
      },
    ],
  },
  {
    title: 'title.schedule',
    path: PATH_NAME.SCHEDULE,
    icon: ScheduleIcon,
    subMenu: [
      {
        title: 'title.schedule.job',
        path: PATH_NAME.SCHEDULE,
        permissions: 1,
      },
      {
        title: 'title.schedule.job.template',
        path: PATH_NAME.JOB_TEMPLATE,
        permissions: 3,
      },
      {
        title: 'title.schedule.job.paymentrules',
        path: PATH_NAME.PAYMENT_RULES,
        permissions: 0
      },
    ]
  },
  {
    title: 'title.payroll',
    path: PATH_NAME.PAYROLL,
    icon: PayrollIcon,
    permissions: 0
  },
  {
    title: 'title.admin',
    path: PATH_NAME.ACCOUNT_SETTINGS,
    icon: AdminIcon,
    subMenu: [
      {
        title: 'title.admin.account.settings',
        path: PATH_NAME.ACCOUNT_SETTINGS,
        permissions: 54
      },
    ],
  },
];
