import { ENV, PATH_NAME } from 'configs';
import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { assignedLocationSelector } from 'store/modules/location-profile';

import { Role } from 'models/user';

import { useCurrentPermission, useCurrentRole } from 'hooks/components/auth';

import { MenuConfig } from '.';
import { userPermission } from 'pro/api/user';

export const useAuthorizedMenu = (menu: MenuConfig[]) => {
  const currentRole = useCurrentRole();
  const [currentPermissions, checKHasPermission] = useCurrentPermission();

  const assignedLocation = useSelector(assignedLocationSelector);

  // const canDisplay = useCallback(
  //   (item: Omit<MenuConfig, 'subMenu' | 'icon'>) => {
  //     if (!currentRole || !currentPermissions?.length) return false;
  //     const { roles, permissions } = item;

  //     if (!roles && !permissions) return true;

  //     if (!roles?.length && !permissions?.length) return false;

  //     const isValidRole = roles?.length ? roles.includes(currentRole) : true;
  //     const isValidPermission = permissions?.length ? checKHasPermission(permissions) : true;

  //     return isValidRole && isValidPermission;
  //   },
  //   [checKHasPermission, currentPermissions?.length, currentRole],
  // );

  return useMemo(() => {
    // if (!menu.length || !currentRole) return [];
    const result: MenuConfig[] = [];

    for (let index = 0; index < menu.length; index++) {
      let menuItem = menu[index];

      if (menuItem.permissions === 0) {
        result.push(menuItem);
      }

      if (menuItem.permissions && menuItem.permissions > 0) {
        userPermission(menuItem.permissions) && result.push(menuItem);
      }

      if (menuItem.subMenu) {
        menuItem.subMenu = menuItem.subMenu.filter((menu: any) => userPermission(menu.permissions) || menu.permissions === 0)
        menuItem.subMenu.length > 0 && result.push(menuItem)
      }

      // if (
      //   menuItem.enableEnv &&
      //   !menuItem.enableEnv.includes(ENV.ENV as 'DEV' | 'STAGING' | 'PROD')
      // ) {
      //   continue;
      // }

      // if (
      //   currentRole === Role.LocationManager &&
      //   assignedLocation?.location_manager_can_create_job === 0 &&
      //   menuItem.path === PATH_NAME.JOB
      // ) {
      //   menuItem.subMenu = undefined;
      // }

      // if (canDisplay(menuItem)) {
      //   if (menuItem.subMenu?.length) {
      //     menuItem = {
      //       ...menuItem,
      //       subMenu: menuItem.subMenu?.filter((item) => canDisplay(item)),
      //     };
      //   }
      //   result.push(menuItem);
      // }

    }

    return result;
  }, [menu, currentRole, assignedLocation]);
};
