import {
  Avatar,
  Box,
  BoxProps,
  Center,
  Collapse,
  Flex,
  Grid,
  HStack,
  Icon,
  Tag,
  VStack,
  useBoolean,
  useBreakpointValue,
} from '@chakra-ui/react';
import noop from 'lodash/noop';
import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { VoidFunc } from 'types';

import { authActions, currentUserSelector } from 'store/modules/auth';

import { colors } from 'themes/foundations/colors';

import { mapContactNumber, mapFullName } from 'apis/utils';

import { Card } from 'components/atoms';
import { Typography } from 'components/atoms';

import { useScreenMode, useTranslate } from 'hooks/common';
import { useRouter } from 'hooks/common/useRouter';

import {
  EditCircleIcon,
  GlobeIcon,
  LetterIcon,
  PhoneIcon,
  PhotoIcon,
  TriangleIcon,
} from 'assets/icons';

import { LAYOUT_CONFIG } from 'configs/layout';
import { PATH_NAME } from 'configs/pathname';

import { LANGUAGES, Language } from 'constant/language';

import { hqStorage, userStorage } from 'configs/browser-storage';

import { PrimaryButton } from 'components/atoms';

import { useProfileContext } from 'containers/ProfileEditContainer';
import { hqUserApi } from 'apis/hq-user';
import i18n from 'configs/i18n';
import { currentHq } from 'store/modules/superHq-user';
import { AppDispatch } from 'store/root-reducer';
export type ProfileInfoProps = {
  infoStyles?: BoxProps;
  onClickProfileIcon?: VoidFunc;
} & BoxProps;

export const ProfileInfo: FC<ProfileInfoProps> = ({ onClickProfileIcon = noop, ...props }) => {
  const user = useSelector(currentUserSelector);
  const router = useRouter();
  const screen = useScreenMode();
  const t = useTranslate();
  const hq = useSelector(currentHq);
  const tagSize = useBreakpointValue(
    {
      base: 'md',
      md: 'md',
      lg: 'md',
      lxl: 'md',
      '2xl': 'lg',
    },
    screen,
  );
  const language = LANGUAGES.find((item) => item.code === user?.language_code) || LANGUAGES[0];

  const { setIsSuperHq } = useProfileContext();
  const dispatch = useDispatch<AppDispatch>();
  const [expanded, { toggle }] = useBoolean(false);

  const handleGoEdit = (isSuperHq: boolean) => {
    setIsSuperHq(isSuperHq);
    onClickProfileIcon();
    router.navigate(PATH_NAME.ACCOUNT_SETTINGS);
  };

  const handleSelectHQ = async () => {
    hqStorage.clear();
    const res = await hqUserApi.removeImpersonateHq();
    userStorage.update(res.data.user);
    dispatch(authActions.updateUser(res.data.user));

    if (i18n.language !== res.data.user.language_code) {
      i18n.changeLanguage(res.data.user.language_code ?? Language.English);
    }

    router.navigate(PATH_NAME.DASHBOARD);
  };

  return (
    <>
      {hq ? (
        <>
          <Box {...props}>
            <Card
              boxShadow={`${colors.tangoOpacity[50]} 0px 0px 3px 0px`}
              bg="tango.100"
              // pl={{ md: '58px', lg: '15px', xl: '20px', '2xl': '58px' }}
              pr="0"
              mb={5}
            >
              <HStack justifyContent="space-around" w="100%">
                <Typography.Title size="lg" fontWeight={100}>
                  {user?.company?.name}
                </Typography.Title>
                <PrimaryButton onClick={handleSelectHQ} fontSize="10px">
                  Select HQ
                </PrimaryButton>
              </HStack>
            </Card>
            <Typography.Title whiteSpace={{ '2xl': 'nowrap' }} capitalize>
              Logged in as:
            </Typography.Title>
            <Card
              boxShadow={`${colors.tangoOpacity[50]} 0px 0px 3px 0px`}
              bg="tango.200"
              pl={{ md: '58px', lg: '15px', xl: '20px', '2xl': '58px' }}
              pr="0"
            >
              <HStack spacing={{ md: '15px', lg: '10px', '2xl': '15px' }}>
                <Avatar
                  boxSize={{
                    md: '78px',
                    lg: '50px',
                    '2xl': '78px',
                  }}
                  bg="white"
                  color="tango.600"
                  src={user?.avatar_url ? user?.avatar_url : ''}
                  icon={
                    <Icon
                      as={PhotoIcon}
                      transition="all 0.2s ease-in"
                      width={{
                        md: '32px',
                        lg: '24px',
                        '2xl': '32px',
                      }}
                      height={{
                        md: '24px',
                        lg: '16px',
                        '2xl': '24px',
                      }}
                    />
                  }
                />

                <VStack
                  position="relative"
                  align="stretch"
                  flex={1}
                  spacing={{ md: '10px', lg: '4px', '2xl': '10px' }}
                  maxW="210px"
                >
                  <Typography.Title size="lg" lineHeight="1" pr="15px" breakWord noOfLines={3}>
                    {user && mapFullName(user)}
                  </Typography.Title>
                  <HStack maxWidth="200px" overflow="hidden" justify="space-between">
                    <Typography.Text
                      size="md"
                      lineHeight="1"
                      opacity={user?.job_title ? 1 : 0}
                      breakWord
                      noOfLines={3}
                    >
                      {user?.job_title}
                    </Typography.Text>
                    <Box pr={{ md: '58px', lg: '15px' }} alignSelf="flex-end">
                      <Icon
                        boxSize={{ lg: '20px', '2xl': '24px' }}
                        cursor="pointer"
                        as={EditCircleIcon}
                        onClick={() => handleGoEdit(true)}
                      />
                    </Box>
                  </HStack>
                </VStack>
              </HStack>
            </Card>
            <Box mt="24px" pl={LAYOUT_CONFIG.sidebar.paddingLeft} pb="2px">
              <Grid
                templateColumns="fit-content(200px) 1fr"
                rowGap={{ base: '16px', '2xl': '18px' }}
                columnGap={{ base: '10px', '2xl': '16px' }}
              >
                <HStack>
                  <PhoneIcon />
                  <Typography.Title
                    whiteSpace={{ '2xl': 'nowrap' }}
                    maxWidth={{ base: '200px', lg: '60px', '2xl': '200px' }}
                    size="md"
                    capitalize
                  >
                    {t('label.phoneNumber')}
                  </Typography.Title>
                </HStack>
                <Flex alignItems="center">
                  <Typography.Text size="md">{user && mapContactNumber(user)}</Typography.Text>
                </Flex>

                <HStack>
                  <LetterIcon />
                  <Typography.Title size="md">{t('label.email')}</Typography.Title>
                </HStack>

                <Typography.Title size="md" isTruncated showTooltip>
                  {user?.email}
                </Typography.Title>

                <HStack>
                  <GlobeIcon />
                  <Typography.Text size="md" variant="bold">
                    {t('label.language')}
                  </Typography.Text>
                </HStack>
                <Box>
                  {language && (
                    <Tag size={tagSize} variant="outlineShadow">
                      {language?.label}
                    </Tag>
                  )}
                </Box>
              </Grid>
            </Box>
          </Box>
          <Box {...props}>
            <>
              <VStack alignItems="flex-start">
                <Typography.Title whiteSpace={{ '2xl': 'nowrap' }} capitalize>
                  HQ Manager
                </Typography.Title>
              </VStack>
            </>
            <Card
              boxShadow={`${colors.tangoOpacity[50]} 0px 0px 3px 0px`}
              bg="tango.100"
              pl={{ md: '58px', lg: '15px', xl: '20px', '2xl': '58px' }}
              pr="0"
            >
              <HStack spacing={{ md: '15px', lg: '10px', '2xl': '15px' }}>
                <Avatar
                  boxSize={{
                    md: '78px',
                    lg: '60px',
                    '2xl': '78px',
                  }}
                  bg="white"
                  color="tango.600"
                  src={hq?.avatar_url ? hq?.avatar_url : ''}
                  icon={
                    <Icon
                      as={PhotoIcon}
                      transition="all 0.2s ease-in"
                      width={{
                        md: '32px',
                        lg: '24px',
                        '2xl': '32px',
                      }}
                      height={{
                        md: '24px',
                        lg: '16px',
                        '2xl': '24px',
                      }}
                    />
                  }
                />

                <VStack
                  position="relative"
                  align="stretch"
                  flex={1}
                  spacing={{ md: '10px', lg: '4px', '2xl': '10px' }}
                  maxW="210px"
                >
                  <Typography.Title size="lg" lineHeight="1" pr="15px" breakWord noOfLines={3}>
                    {hq && mapFullName(hq)}
                  </Typography.Title>
                  <HStack maxWidth="200px" overflow="hidden" justify="space-between">
                    <Typography.Text
                      size="md"
                      lineHeight="1"
                      opacity={hq?.job_title ? 1 : 0}
                      breakWord
                      noOfLines={3}
                    >
                      {hq?.job_title}
                    </Typography.Text>
                    <Box pr={{ md: '58px', lg: '15px' }} alignSelf="flex-end">
                      <Icon
                        boxSize={{ lg: '20px', '2xl': '24px' }}
                        cursor="pointer"
                        as={EditCircleIcon}
                        onClick={() => handleGoEdit(false)}
                      />
                    </Box>
                  </HStack>
                </VStack>
              </HStack>

              <Center h="100%" top="0" bottom="0" right="10px" position="absolute" ml="0">
                <Icon
                  w="16px"
                  h="auto"
                  cursor="pointer"
                  transform={expanded ? 'rotate(0)' : 'rotate(180deg)'}
                  transition="transform 0.2s ease-in"
                  as={TriangleIcon}
                  onClick={toggle}
                />
              </Center>
            </Card>
            <Collapse in={expanded}>
              <Box mt="24px" pl={LAYOUT_CONFIG.sidebar.paddingLeft} pb="2px">
                <Grid
                  templateColumns="fit-content(200px) 1fr"
                  rowGap={{ base: '16px', '2xl': '18px' }}
                  columnGap={{ base: '10px', '2xl': '16px' }}
                >
                  <HStack>
                    <PhoneIcon />
                    <Typography.Title
                      whiteSpace={{ '2xl': 'nowrap' }}
                      maxWidth={{ base: '200px', lg: '60px', '2xl': '200px' }}
                      size="md"
                      capitalize
                    >
                      {t('label.phoneNumber')}
                    </Typography.Title>
                  </HStack>
                  <Flex alignItems="center">
                    <Typography.Text size="md">{hq && mapContactNumber(hq)}</Typography.Text>
                  </Flex>

                  <HStack>
                    <LetterIcon />
                    <Typography.Title size="md">{t('label.email')}</Typography.Title>
                  </HStack>

                  <Typography.Title size="md" isTruncated showTooltip>
                    {hq?.email}
                  </Typography.Title>

                  <HStack>
                    <GlobeIcon />
                    <Typography.Text size="md" variant="bold">
                      {t('label.language')}
                    </Typography.Text>
                  </HStack>
                  <Box>
                    {language && (
                      <Tag size={tagSize} variant="outlineShadow">
                        {language?.label}
                      </Tag>
                    )}
                  </Box>
                </Grid>
              </Box>
            </Collapse>
          </Box>
        </>
      ) : (
        <Box {...props}>
          <Card
            boxShadow={`${colors.tangoOpacity[50]} 0px 0px 3px 0px`}
            bg="tango.200"
            pl={{ md: '58px', lg: '15px', xl: '20px', '2xl': '58px' }}
            pr="0"
          >
            <HStack spacing={{ md: '15px', lg: '10px', '2xl': '15px' }}>
              <Avatar
                boxSize={{
                  md: '78px',
                  lg: '50px',
                  '2xl': '78px',
                }}
                bg="white"
                color="tango.600"
                src={user?.avatar_url ? user?.avatar_url : ''}
                icon={
                  <Icon
                    as={PhotoIcon}
                    transition="all 0.2s ease-in"
                    width={{
                      md: '32px',
                      lg: '24px',
                      '2xl': '32px',
                    }}
                    height={{
                      md: '24px',
                      lg: '16px',
                      '2xl': '24px',
                    }}
                  />
                }
              />

              <VStack
                position="relative"
                align="stretch"
                flex={1}
                spacing={{ md: '10px', lg: '4px', '2xl': '10px' }}
                maxW="210px"
              >
                <Typography.Title size="lg" lineHeight="1" pr="15px" breakWord noOfLines={3}>
                  {user?.display_name}
                </Typography.Title>
                <HStack maxWidth="200px" overflow="hidden" justify="space-between">
                  <Typography.Text
                    size="md"
                    lineHeight="1"
                    opacity={user?.job_title ? 1 : 0}
                    breakWord
                    noOfLines={3}
                  >
                    {user?.job_title}
                  </Typography.Text>
                  <Box pr={{ md: '58px', lg: '15px' }} alignSelf="flex-end">
                    <Icon
                      boxSize={{ lg: '20px', '2xl': '24px' }}
                      cursor="pointer"
                      as={EditCircleIcon}
                      onClick={() => handleGoEdit(false)}
                    />
                  </Box>
                </HStack>
              </VStack>
            </HStack>
          </Card>
          <Box mt="24px" pl={LAYOUT_CONFIG.sidebar.paddingLeft} pb="2px">
            <Grid
              templateColumns="fit-content(200px) 1fr"
              rowGap={{ base: '16px', '2xl': '18px' }}
              columnGap={{ base: '10px', '2xl': '16px' }}
            >
              <HStack>
                <PhoneIcon />
                <Typography.Title
                  whiteSpace={{ '2xl': 'nowrap' }}
                  maxWidth={{ base: '200px', lg: '60px', '2xl': '200px' }}
                  size="md"
                  capitalize
                >
                  {t('label.phoneNumber')}
                </Typography.Title>
              </HStack>
              <Flex alignItems="center">
                <Typography.Text size="md">{user && mapContactNumber(user)}</Typography.Text>
              </Flex>

              <HStack>
                <LetterIcon />
                <Typography.Title size="md">{t('label.email')}</Typography.Title>
              </HStack>

              <Typography.Title size="md" isTruncated showTooltip>
                {user?.email}
              </Typography.Title>

              <HStack>
                <GlobeIcon />
                <Typography.Text size="md" variant="bold">
                  {t('label.language')}
                </Typography.Text>
              </HStack>
              <Box>
                {language && (
                  <Tag size={tagSize} variant="outlineShadow">
                    {language?.label}
                  </Tag>
                )}
              </Box>
            </Grid>
          </Box>
        </Box>
      )}
    </>
  );
};
