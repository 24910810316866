import {
  FireWithBadgeIcon,
  LeftOutlinedIcon,
  MenuDashOutlineIcon,
  NotificationWithBadgeIcon,
} from 'assets/icons';
import { useDeviceMode, useRouter } from 'hooks/common';
import { FC, memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { configActions, headerTitleSelector } from 'store/modules/config';
import { DeviceMode } from 'types';

interface HeaderProps {
  onClick: () => void;
}

const Header: FC<HeaderProps> = ({ onClick }) => {
  const router = useRouter();
  const dispatch = useDispatch();
  const device = useDeviceMode();
  const { headerTitle } = useSelector(headerTitleSelector);

  const handleBack = () => {
    router.navigate('/');
    dispatch(
      configActions.setHeaderTitle({
        headerTitle: '',
      }),
    );
  };

  return (
    <div className="px-5 py-0 lg:py-5 flex items-center justify-between w-full lg:bg-[#F6F7FA]">
      <div className="flex items-center gap-1">
        <button type="button" onClick={handleBack}>
          <LeftOutlinedIcon />
        </button>
        <h3 className="text-sm lg:text-2xl font-bold uppercase text-black">{headerTitle}</h3>
      </div>

      <div className="flex items-center justify-between gap-1">
        <NotificationWithBadgeIcon
          width={device === DeviceMode.Mobile ? '27' : '41'}
          height={device === DeviceMode.Mobile ? '27' : '41'}
        />
        <FireWithBadgeIcon
          width={device === DeviceMode.Mobile ? '28' : '41'}
          height={device === DeviceMode.Mobile ? '27' : '41'}
        />
        <button type="button" onClick={onClick} className="flex lg:hidden">
          <MenuDashOutlineIcon />
        </button>
      </div>
    </div>
  );
};

export default memo(Header);
